import React, { useRef, useState } from "react";
import { BsArrowLeftCircleFill, BsArrowRightCircleFill } from "react-icons/bs";
import "./carousel.scss";

function Carousel({data}: any) {
    const [current, setCurrent] = useState(0);

    const nextSlide = () => {
        setCurrent(current === data.length - 1 ? 0 : current + 1);
    };

    const prevSlide = () => {
        setCurrent(current === 0 ? data.length - 1 : current - 1);
    };

    return (
        <div className="carousel">   
            {data.map((slide: any, index: any) => {
                return (
                    <div key={index} className={current === index ? "slide" : "slide slide-hidden"}>
                        <img 
                        src={`${slide.src}`} 
                        alt={slide.alt}
                        className="slideImage"
                         />
                        <div className="textContainer">
                            {   // LET'S GO OMG I LOVE THIS SHIET
                                slide.text !== null && slide.text !== undefined && slide.text !== "" ?
                                <div className="textSlide">
                                    <div className="slideTitle">
                                        <h2>{slide.title}</h2>
                                    </div>
                                    <hr className="slideLine"/>
                                    <div className="slideText">
                                        <b>{slide.text}</b>
                                    </div>
                                </div> 
                                : 
                                <div className="slideSpecialText">
                                    <b>Integrated Technology<br />For<br />The Future</b>
                                </div>
                            }
                        </div>
                    </div>
                );
            })}
            <BsArrowLeftCircleFill className="arrow arrow-left" onClick={prevSlide} />
            <BsArrowRightCircleFill className="arrow arrow-right" onClick={nextSlide} />
            <span className="indicators">
                {data.map((_: any, index: any) => {
                    return (
                        <span 
                        key={index} 
                        className={current === index ? "indicator" : "indicator indicator-inactive"} 
                        onClick={() => setCurrent(index)} />
                    );
                })}
            </span>
        </div>
    );
}

export default Carousel;