import React from 'react'
import './contact.scss'

function Contact() {
  return (
    <div>
      <h2>Contact Us!</h2>
      
    </div>
  )
}

export default Contact