import React, { useEffect } from 'react'
import { Link, NavLink } from 'react-router-dom'
import './navbar.scss'

function Navbar() {
  const logoNormal = '/assets/logo.svg'
  const logoSticky = '/assets/logo-no-name.svg'
  // Add a hook so when the user scrolls down, the navbar will have a new class
  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    function handleScroll() {
      const navbar = document.getElementsByClassName('navHeader')[0]
      const logo = document.getElementsByClassName('logo')[0]
      if (window.scrollY > navbar.scrollTop) {
        navbar.classList.add('-sticky')
      } else {
        navbar.classList.remove('-sticky')
      }
      if (navbar.classList.contains('-sticky')) {
        logo.setAttribute('src', logoSticky)
        logo.classList.add('-noname')
      }
      else {
        logo.setAttribute('src', logoNormal)
        logo.classList.remove('-noname')
      }
    }
  })
  return (
    <header className='navHeader'>
      <div className='navbar'>
          <div className='logoImg'><Link to='/'><img src={logoNormal} alt='SIP Solutions'className='logo' /></Link></div>
          <nav className='menu'>
            <ul className='menu__list'>
                <li><NavLink className='menu__item' to='/products'>Products</NavLink></li>
                <li><NavLink className='menu__item' to='/services'>Services</NavLink></li>
                <li><NavLink className='menu__item' to='/partners'>Partners</NavLink></li>
                {/*<li><NavLink className='menu__item' to='/contact'>Contact</NavLink></li>*/}
            </ul>
          </nav>
      </div>
    </header>
  )
}

export default Navbar