import React from 'react';
import { createBrowserRouter, Outlet } from 'react-router-dom';
import Home from './home/home';
import Services from './home/services';
import Products from './home/products';
import Partners from './home/partners';
import Contact from './home/contact';
import NavBar from './components/navbar';
import Footer from './components/footer';

function appLayout() { 
    return (
    <div>
        <NavBar />
        <Outlet />
        <Footer />
    </div>
)};

const router =
    createBrowserRouter([{
    element: appLayout(),
    children: [
        { path: '/', element: <Home /> },
        { path: '/services', element: <Services /> },
        { path: '/products', element: <Products /> },
        { path: '/partners', element: <Partners /> },
        { path: '/contact', element: <Contact /> }
    ]}
])


export default router