import React from 'react';
import './home.scss';
import Carousel from '../library/carousel';
import slides from '../data/slides.json';

function App() {
  // I LOVE JSONS
  const slideData = slides.slides;
  return (
    <div className="App">
      {/*<h1 className='title'>Comfort and Confidence with every <b>SIP</b></h1>*/}
      <Carousel className="carousel" data={slideData} />
      {/*HELPPPPPP MMEEEE*/}
      <section className="pageSection">
        <div className="pageLinks">
          <div className="pageLink __products">
            <div className="text __products">
              <div className="products__title">
                <h2>Products</h2>
              </div>
              <div className="products__content">
                <strong>View our products</strong>
              </div>
              <div className="products__button">
                <a href="/products" className="button __products">View Products</a>
              </div>
            </div>
          </div>

          <div className="pageLink __services">
            <div className="text __services">
              <div className="services__title">
                <h2>Services</h2>
              </div>
              <div className="services__content">
                <strong>View our services</strong>
              </div>
              <div className="services__button">
                <a href="/services" className="button __services">View Services</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="about">
        <h2>About Us</h2>
        <p className="about -text">
        Originally an independent, international technology consulting firm, <b>SIP</b> specialized in design and integration services for hospitality industry projects. 
        Our expertise helped owners, developers, investors, and operators maximize technology investments and boost asset value. 
        Evolving beyond consulting, we now offer comprehensive solutions to the hospitality sector and beyond, solidifying our position as a total solution provider.
        <br /><br />
        With offices in Bali and Jakarta, our company collaborates with a diverse range of hotels, from budget-friendly options to luxurious, high-star-rated properties, including mixed-use developments, business hotels, resorts, and theme hotels. 
        Additionally, we are expanding our expertise to include commercial, industrial, and residential projects.
        Our team comprises experienced professionals with a strong technical and operational background in technology solutions, having previously worked with top global companies, possessing a wealth of industry expertise and knowledge.
        </p>
      </div>
    </div>
  );
}

export default App;
