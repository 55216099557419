import React from 'react'
import './partners.scss'

function Patners() {
  return (
    <div className="partners">
      <h2>Partnering for Technological Excellence</h2>
    </div>
  )
}

export default Patners