import React from 'react'
import './footer.scss'

function Footer() {
  return (
    <div className='footer'>
      <p>Copyright ©2024 SIP GLOBAL SOLUTIONS</p>
    </div>
  )
}

export default Footer